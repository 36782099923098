.aboutMeHeaderBox {
    background-color: rgb(228, 244, 252);
}

.aboutMeHeader {
    color:#0c6ec2;
    background-color: rgb(228, 244, 252);
    margin: 0;
    padding: 20px 40px;
    margin: 0 auto;
    max-width: 1300px;
}

.aboutMeBackground {
    background-color: rgb(228, 244, 252);
    margin: 0 0 0 0;
    padding: 20px 0 50px 0px;
    display: flex;
    justify-content: space-evenly;
}

.aboutMeContent {
    display: flex;
    justify-content: center;
    margin: 0 200px;
    max-width: 1200px;
    margin: 0 auto;
}

.aboutMePortrait img {
    display: flex;
    justify-content: center;
    margin: auto;
}

.aboutMeText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 0 30px;
}

.aboutMeText h3 {
    margin: 0;
}

@media only screen and (min-width: 280px) and (max-width: 1103px) {

    .aboutMeContent {
        display: flex;
        justify-content: center;
        margin: 0 200px;
        flex-direction: column;
    }

    .aboutMeText {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        text-align: center;
    }

    .aboutMeText h3 {
        margin: 10px 0 0 0;
    }
}

@media only screen and (min-width: 280px) and (max-width: 528px) {
    .aboutMePortrait img {
        width: 250px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
}

@media only screen and (min-width: 280px) and (max-width: 467px) {
    .aboutMeHeader {
        text-align: center;
    }
}

@media only screen and (min-width: 280px) and (max-width: 467px) {
    .aboutMePortrait img {
        width: 200px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
}
.projectsHeaderBox {
    background-color: rgb(201, 233, 255);
}

.projectsHeader {
    color:rgb(12, 110, 194);
    background-color: rgb(201, 233, 255);
    margin: 0;
    padding: 30px 40px;
    max-width: 1300px;
    margin: 0 auto;
}

.projects {
    background-color: rgb(201, 233, 255);
    padding: 100px 0; 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 100px;
    padding: 20px 50px 80px 50px;
}

.project {
    background-color: rgba(255, 255, 255, 0.559);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.211);
    border-radius: 30px;
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    margin: 0 40px;
}

.projectInfo h5 {
    font-size: 2em;
    margin: 0px;
}

.projectInfoLanguagesUsed {
    font-size: 12px;
}

.projectButtons {
    background-color: rgb(12, 110, 194);
    font-size: 0.8em;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 10px;
    margin-right: 5px;
    cursor: pointer;
}

.project img {
    width: 50%;
}

@media only screen and (min-width: 280px) and (max-width: 844px) {

    .project {
        background-color: rgba(255, 255, 255, 0.559);
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.211);
        border-radius: 30px;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .projectInfo {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
        text-align: center;
    }

    .projectInfo h5 {
        font-size: 2em;
        margin: 0px;
        text-align: center;
    }

    .projectButtons {
        background-color: rgb(12, 110, 194);
        border: none;
        color: white;
        border-radius: 20px;
        padding: 10px;
        margin: 5px 0;
        margin-right: 5px;
        cursor: pointer;
    }

    .project img {
        width: 80%;
    }

    .test {
        margin-left: 20px;
    }
}

@media only screen and (min-width: 280px) and (max-width: 467px) {

    .projectsHeader {
        text-align: center;
    }
}
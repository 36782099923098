.skillsHeaderBox {
    background-color: rgb(214, 238, 255);
}

.skillsHeader {
    color:#0c6ec2;
    background-color: rgb(214, 238, 255);
    margin: 0;
    padding: 20px 40px;
    margin: 0 auto;
    max-width: 1300px;
}

.skills {
    background-color: rgb(214, 238, 255);
    margin: 0 0 0 0;
    padding: 0 0 20px 0;
}

.skillsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1500px;
    margin: 0 auto;
}

.skills img {
    width: 70%;
}

.svgContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80px;
}

.svgSizeAndColor {
    filter: invert(30%) sepia(95%) saturate(4487%) hue-rotate(190deg) brightness(96%) contrast(91%);
    width: 0%;
    display: flex;
    align-items: center;
    align-self: center;
}

.svgTitle {
    color: rgb(0, 108, 191);
    font-size: 0.8em;
    margin: 10px 0;
    text-align: center;
}

@media only screen and (min-width: 280px) and (max-width: 467px) {

    .skillsHeader {
        text-align: center;
    }
}